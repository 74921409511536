import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import axios from "axios";
import { stringify } from "qs";

import moment from "moment";
import "moment/locale/nl";

import * as serviceWorker from "./serviceWorker";

import App from "./App";

import "./scss/main.scss";

const development = process.env.REACT_APP_ENVIRONMENT === "development";

if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://43dc5cb1dd1d4c6a82a0447f8764b286@sentry1.zsnode.com/35",
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
    });
}

moment.locale("nl");

if(development) {
    axios.defaults.baseURL = "/v1/app/";
} else {
    const SWENGAGE_API_URL = process.env.REACT_APP_SWENGAGE_API_URL ? process.env.REACT_APP_SWENGAGE_API_URL : "api.swengage.com"
    axios.defaults.baseURL = "https://" + SWENGAGE_API_URL + "/v1/app/";
}
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

ReactDOM.render((
    <App/>
), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
