import React, {
    useMemo
} from "react";
import {
    useHistory,
    useParams
} from "react-router-dom";

import SurveyQuestion from "./SurveyQuestion";

function SurveyProcess({ survey, baseUrl, surveyInviteToken }) {
    const params = useParams();
    const history = useHistory();

    const questionIndex = useMemo(() => {
        const questionIndexInput = parseInt(params.questionIndex);
        if(isNaN(questionIndexInput)) {
            return 0;
        }
        return questionIndexInput - 1;
    }, [params]);

    const nextQuestion = useMemo(() => {
        return () => {
            if(questionIndex >= survey.questions.length - 1) {
                history.push(baseUrl + "/thanks");
            } else {
                history.push(baseUrl + "/questions/" + (questionIndex + 2));
            }
        };
    }, [questionIndex, history, baseUrl, survey.questions]);
    const previousQuestion = useMemo(() => {
        return () => {
            if(questionIndex === 0) {
                history.push(baseUrl);
            } else {
                history.push(baseUrl + "/questions/" + (questionIndex));
            }
        };
    }, [questionIndex, history, baseUrl]);

    return (
        <SurveyQuestion
            survey={ survey }
            question={ survey.questions[questionIndex] }
            surveyInviteToken={ surveyInviteToken }
            currentQuestionIndex={ questionIndex }
            nextQuestion={ nextQuestion }
            previousQuestion={ previousQuestion }
        />
    )
}

export default React.memo(SurveyProcess);
