import React from "react";
import {
    Link
} from "react-router-dom";

import sampleImage1 from "../../img/sample/sample-image-intro.jpg";
import logo from "../../img/swengage-logo-white.svg";

function SurveyIntro({ survey, baseUrl }) {
    return (
        <div className="d-flex flex-column" style={{ backgroundColor: "#3a3d42", minHeight: "100vh" }}>
            <div
                className="w-100"
                style={{
                    backgroundImage: "url(" + sampleImage1 + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight: "25vh"
                }}
            />
            <div className="container text-white py-5 flex-grow-1 d-flex flex-column">
                <div className="d-flex h-100 flex-column flex-grow-1">
                    <div className="flex-grow-1">
                        <h1>{ survey.name }</h1>
                        <p>
                            { survey.description }
                        </p>
                        <Link to={ baseUrl + "/questions" } className="btn btn-primary btn-lg px-4">
                            Start
                        </Link>
                    </div>
                    <div className="mt-5">
                        <img src={ logo } alt="SwEngage" style={{ width: "150px" }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SurveyIntro);
