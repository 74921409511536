import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Spinner
} from "react-bootstrap";

function SurveyQuestionAnswerSection({ question, surveyInviteToken, currentQuestionIndex, nextQuestion }) {
    const [response, setResponse] = useState(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const buttons = [
        { color: "success", icon: "fa-smile", data: "like" },
        { color: "light", icon: "fa-meh", data: "neutral" },
        { color: "danger", icon: "fa-frown", data: "dislike" }
    ]
    const onSubmit = useMemo(() => {
        return () => {
            if(response.length === 0) {
                setError("Selecteer alstublieft een antwoord.");
                return;
            }
            if(message.length === 0) {
                setError("Vul alstublieft een onderbouwing in.");
                return;
            }
            setLoading(true);
            axios.post("/setSurveyQuestionAnswer", {
                surveyInviteToken,
                surveyQuestionId: question.id,
                response,
                message
            })
                .then((response) => {
                    if(response.data.valid) {
                        nextQuestion();
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError("Er ging iets fout met het versturen van uw antwoord. (" + response.data.error + ")");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                    setError("Er ging iets fout met het versturen van uw antwoord.");
                });
        }
    }, [question, surveyInviteToken, nextQuestion, response, message]);
    useEffect(() => {
        setResponse(null);
        setMessage("");
        setLoading(false);
        setError(null);
    }, [currentQuestionIndex]);
    return (
        <div>
            { error && (
                <Alert variant="danger">
                    { error }
                </Alert>
            )}
            <div className="row text-center">
                { buttons.map((button, index) => {
                    const onClick = () => {
                        setResponse(button.data);
                    }
                    return (
                        <div className="col" key={ index }>
                            <Button
                                variant={ button.color }
                                size="lg"
                                style={{ fontSize: "2rem" }}
                                onClick={ onClick }
                                active={ response === button.data }
                                disabled={ loading }
                            >
                                <i className={ "fas fa-fw " + button.icon }/>
                            </Button>
                        </div>
                    )
                })}
            </div>
            { response !== null && (
                <React.Fragment>
                    <Form.Group controlId="answerMessage" className="mb-0 mt-4">
                        <Form.Label style={{ fontSize: "1.5rem" }}>Onderbouw uw antwoord</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={ message }
                            onChange={ (event) => setMessage(event.target.value) }
                            disabled={ loading }
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        size="lg"
                        style={{ fontSize: "1.2rem" }}
                        className="mt-2 float-right px-4"
                        onClick={ onSubmit }
                        disabled={ loading }
                    >
                        { loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-4"/>
                        )}
                        Volgende vraag
                    </Button>
                </React.Fragment>
            )}
        </div>
    )
}

export default React.memo(SurveyQuestionAnswerSection);
