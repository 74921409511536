import React from "react";

import SurveyQuestionImageCarousel from "./SurveyQuestionImageCarousel";
import SurveyQuestionContent from "./SurveyQuestionContent";

import sampleImage1 from "../../img/sample/sample-image-1.jpg";

function SurveyQuestion({ survey, question, surveyInviteToken, currentQuestionIndex, previousQuestion, nextQuestion }) {
    const images = question.images.length > 0 ? question.images : [{ id: 0, url: sampleImage1 }];
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-7 p-0" style={{ backgroundColor: "#4287f5" }}>
                    <div style={{
                        position: "sticky",
                        top: 0,
                        minHeight: "100vh"
                    }}>
                        <SurveyQuestionImageCarousel
                            images={ images }
                        />
                    </div>
                </div>
                <div className="col-md-5 text-white p-5 d-flex flex-column" style={{ backgroundColor: "#3a3d42", minHight: "100vh" }}>
                    <SurveyQuestionContent
                        survey={ survey }
                        question={ question }
                        surveyInviteToken={ surveyInviteToken }
                        currentQuestionIndex={ currentQuestionIndex }
                        nextQuestion={ nextQuestion }
                        previousQuestion={ previousQuestion }
                    />
                </div>
            </div>
        </div>
    )
}

export default React.memo(SurveyQuestion);
