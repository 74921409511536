import React from "react";
import {
    Carousel
} from "react-bootstrap";

function SurveyQuestionImageCarousel({ images }) {
    const commonStyle = {
        height: "100vh",
        position: "sticky"
    }
    if(images.length === 1) {
        return (
            <div
                className="w-100"
                style={{
                    backgroundImage: "url(" + images[0].url + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    ...commonStyle
                }}
            />
        )
    }
    return (
        <Carousel
            style={{
                ...commonStyle
            }}
        >
            { images.map((image) => (
                <Carousel.Item key={ image.id }>
                    <div
                        className="w-100"
                        style={{
                            backgroundImage: "url(" + image.url + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            height: "100vh"
                        }}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default React.memo(SurveyQuestionImageCarousel);
