import React from "react";
import {
    Button
} from "react-bootstrap";
import SurveyQuestionAnswerSection from "./SurveyQuestionAnswerSection";

function SurveyQuestionContent({ survey, question, surveyInviteToken, currentQuestionIndex, nextQuestion, previousQuestion }) {
    return (
        <React.Fragment>
            <div className="flex-grow-1">
                <h4 className="mb-5">{ survey.name }</h4>
                <Button variant="link" size="sm" onClick={ previousQuestion }>
                    <i className="fas fa-chevron-left mr-2"/>
                    Terug
                </Button>
                <h1 style={{ fontSize: "3rem" }}>{ question.title }</h1>
                <p style={{ fontSize: "1.1rem" }}>
                    <b>{ currentQuestionIndex + 1 } / { survey.questions.length }</b>
                </p>
                <p style={{ fontSize: "1.5rem" }}>
                    { question.description }
                </p>
            </div>
            <SurveyQuestionAnswerSection
                question={ question }
                surveyInviteToken={ surveyInviteToken }
                currentQuestionIndex={ currentQuestionIndex }
                nextQuestion={ nextQuestion }
            />
        </React.Fragment>
    )
}

export default React.memo(SurveyQuestionContent);
