import React from "react";

import logo from "../img/swengage-logo.svg";

function Page404() {
    return (
        <div className="login-background h-100vh d-flex justify-content-center align-items-center">
            <div className="card">
                <div className="card-body text-center">
                    <div className="m-4">
                        <img src={ logo } alt="SwEngage" style={{ width: "100%", maxWidth: "400px" }}/>
                    </div>
                    <h1>404 Not Found</h1>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Page404);
