import React from "react";
import axios from "axios";
import {
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Loading from "../components/Loading";
import Page404 from "./Page404";
import SurveyProcess from "../components/survey/SurveyProcess";
import SurveyIntro from "../components/survey/SurveyIntro";
import SurveyOutro from "../components/survey/SurveyOutro";

class Survey extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            survey: null,
            error: null,
            errorCode: null
        }
    }

    componentDidMount() {
        this.getSurvey();
    }

    getSurvey() {
        const surveyInviteToken = this.props.match.params.surveyInviteToken;
        axios.post("/getSurvey", { surveyInviteToken })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ survey: response.data.survey });
                } else {
                    this.setState({ error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setState({ error: "Er was een fout bij het ophalen van de data." });
            });
    }

    render() {
        const { error, errorCode, survey } = this.state;
        const surveyInviteToken = this.props.match.params.surveyInviteToken;
        const baseUrl = "/survey/" + surveyInviteToken;
        return (
            <React.Fragment>
                { error ? (
                    errorCode === "DOESNT_EXIST" ? (
                        <Page404/>
                    ) : (
                        <Alert variant="danger">{ error }</Alert>
                    )
                ) : !survey ? (
                    <div className="h-100vh d-flex align-items-center">
                        <div className="w-100">
                            <Loading/>
                        </div>
                    </div>
                ) : (
                    <Switch>
                        <Route path={ [baseUrl + "/questions/:questionIndex", baseUrl + "/questions"] }>
                            <SurveyProcess
                                survey={ survey }
                                baseUrl={ baseUrl }
                                surveyInviteToken={ surveyInviteToken }
                            />
                        </Route>
                        <Route path={ baseUrl + "/thanks" }>
                            <SurveyOutro
                                survey={ survey }
                            />
                        </Route>
                        <Route path={ baseUrl }>
                            <SurveyIntro
                                survey={ survey }
                                baseUrl={ baseUrl }
                            />
                        </Route>
                    </Switch>
                )}
            </React.Fragment>
        )
    }
}

export default Survey;
