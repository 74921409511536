import React from "react";

import sampleImage1 from "../../img/sample/sample-image-intro.jpg";
import logo from "../../img/swengage-logo-white.svg";

function SurveyOutro({ survey }) {
    return (
        <div className="d-flex flex-column" style={{ backgroundColor: "#3a3d42", height: "100vh" }}>
            <div
                className="w-100"
                style={{
                    backgroundImage: "url(" + sampleImage1 + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    height: "300px"
                }}
            />
            <div className="container text-white py-5 flex-grow-1">
                <div className="d-flex h-100 flex-column">
                    <div className="flex-grow-1">
                        <h1>{ survey.name }</h1>
                        <p>
                            Bedankt voor het invullen van de survey!
                        </p>
                        <p>
                            Je kunt dit scherm nu afsluiten.
                        </p>
                    </div>
                    <div className="mt-3">
                        <img src={ logo } alt="SwEngage" style={{ width: "150px" }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SurveyOutro);
