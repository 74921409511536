import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";

import Survey from "./pages/Survey";
import Page404 from "./pages/Page404";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/survey/:surveyInviteToken" component={Survey} />
                <Route path="/" component={Page404} />
            </Switch>
        </Router>
    );
}

export default App;
